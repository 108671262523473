
import BTag from 'buetify/lib/components/tag/BTag';
import BTagList from 'buetify/lib/components/tag/BTagList';
import { defineComponent } from 'vue';
export default defineComponent({
	name: 'tag-list-example',
	components: {
		BTag,
		BTagList
	}
});
