
import BTag from 'buetify/lib/components/tag/BTag';
import { defineComponent, reactive } from 'vue';
export default defineComponent({
	name: 'closable-tag-example',
	components: {
		BTag
	},
	setup() {
		const isActive = reactive({
			a: true,
			b: true,
			c: true,
			d: true,
			e: true
		});
		function close(key: keyof typeof isActive) {
			isActive[key] = false;
		}

		return {
			isActive,
			close
		};
	}
});
