
import BTag from 'buetify/lib/components/tag/BTag';
import BTagList from 'buetify/lib/components/tag/BTagList';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'tags-variants-and-sizes-example',
	components: {
		BTagList,
		BTag
	}
});
