
import BHorizontalDivider from 'buetify/lib/components/layout/divider/BHorizontalDivider';
import { defineComponent } from 'vue';
import { ApiView } from '../../../../components/apiView';
import ExampleView from '../../../../components/exampleView/ExampleView.vue';
import AttachedTagListExample from './examples/AttachedTagListExample.vue';
import AttachedTagListExampleCode from '!!raw-loader!./examples/AttachedTagListExample.vue';
import ClosableExample from './examples/ClosableExample.vue';
import ClosableExampleCode from '!!raw-loader!./examples/ClosableExample.vue';
import GroupedTagListExample from './examples/GroupedTagListExample.vue';
import GroupedTagListExampleCode from '!!raw-loader!./examples/GroupedTagListExample.vue';
import Simple from './examples/SimpleExample.vue';
import SimpleCode from '!!raw-loader!./examples/SimpleExample.vue';
import ColorsAndStates from './examples/ColorsAndStatesExample.vue';
import ColorsAndStatesCode from '!!raw-loader!./examples/ColorsAndStatesExample.vue';
import { api } from './api';
import TagListExample from './examples/TagListExample.vue';
import TagListExampleCode from '!!raw-loader!./examples/TagListExample.vue';

export default defineComponent({
	name: 'tag-documentation',
	components: {
		ExampleView,
		ColorsAndStates,
		ApiView,
		Simple,
		BHorizontalDivider,
		ClosableExample,
		TagListExample,
		AttachedTagListExample,
		GroupedTagListExample
	},
	setup() {
		return {
			apis: api,
			ColorsAndStatesCode,
			SimpleCode,
			ClosableExampleCode,
			TagListExampleCode,
			AttachedTagListExampleCode,
			GroupedTagListExampleCode
		};
	}
});
